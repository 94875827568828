/* global Fancybox */
/* eslint-env jquery */

'use strict';

/*
 * ---------------------------------------------------------------
 * Non jQuery
 * ---------------------------------------------------------------
 */

/*
 * Initialize Fancybox image zoom and bind it to custom zoom button
 */
Fancybox.bind('.content-gallery-link, .fancybox a, .entry-thumb a', {
	Thumbs: {
		showOnStart: true,
	},
	Toolbar: {
		display: {
			left: ['infobar'],
			middle: [],
			right: ['slideshow', 'thumbs', 'iterateZoom', 'close'],
		},
	},
});

/*
 * ---------------------------------------------------------------
 * jQuery
 * ---------------------------------------------------------------
 */

/*
 * Initialize jQuery
 */

jQuery(function ($) {
	// On mobile & Tablet devices only
	const handheldOnly = window.matchMedia('(max-width: 992px)');

	/*
	 * ---------------------------------------------------------------
	 * Ajax Search
	 * ---------------------------------------------------------------
	 */

	/*
	 * Event: OnClick - Hide search bar button
	 *
	 * Hide search bar
	 */

	$(document).on('click', '#hideSearchBar', function (event) {
		event.preventDefault();
		$('#siteSearch').removeClass('site-search--visible');
		$('#keyword').val('');
		$('#siteSearchContent').empty();
		$('#siteSearchResults').removeClass('site-search-results--visible');
	});

	/*
	 * Event: OnClick - Show search bar buttons: desktop header, mobile topbar, 404 page
	 *
	 * Show search bar
	 */

	$(document).on(
		'click',
		'#showSearchBar, #showMobileSearchBar, #show404SearchBar',
		function (event) {
			event.preventDefault();
			$('#siteSearch')
				.addClass('site-search--visible')
				.delay(500)
				.queue(function () {
					$('#keyword').focus();
				});
		}
	);

	/*
	 * ---------------------------------------------------------------
	 * Sliders
	 * ---------------------------------------------------------------
	 */

	/*
	 * Primary Slider
	 */

	$('#sliderPrimary').slick({
		mobileFirst: true,
		arrows: true,
		dots: false,
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		autoplay: true,
		autoplaySpeed: 6000,
		pauseOnHover: false,
		variableWidth: false,
		fade: false,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					dots: true,
				},
			},
		],
	});

	/*
	 * ---------------------------------------------------------------
	 * Window, Body & HTML
	 * ---------------------------------------------------------------
	 */

	/*
	 * Event: OnScroll - Window
	 *
	 * Sticky navigation & and search bar
	 */

	$(window).on('scroll', function () {
		if ($(window).scrollTop() > 200) {
			$('#masthead').addClass('site-header--sticky');
			$('#siteSearch').addClass('site-search--sticky');
			$('#scrollToTop').addClass('btn-scroll--visible');
		} else {
			$('#masthead').removeClass('site-header--sticky');
			$('#siteSearch').removeClass('site-search--sticky');
			$('#scrollToTop').removeClass('btn-scroll--visible');
		}
	});

	/*
	 * Event: OnClick - Body overlay
	 *
	 * Hide overlay, mobile menu and mini cart if open
	 */

	$(document).on('click', '#bodyOverlay', function (event) {
		event.preventDefault();
		const menu = $('#sidebarNav');

		if (menu.hasClass('sidebar-nav--visible')) {
			menu.removeClass('sidebar-nav--visible');
		}

		$(this).removeClass('body-overlay--visible');
	});

	/*
	 * Event: OnClick - Scroll to top button
	 *
	 * Scroll to top
	 */

	$('#scrollToTop').on('click', function (event) {
		event.preventDefault();
		const target = $(this).attr('data-target');
		$('html, body').animate({ scrollTop: $(target).offset().top }, 1000);
	});

	/*
	 * ---------------------------------------------------------------
	 * Mobile Menu
	 * ---------------------------------------------------------------
	 */

	/*
	 * Event: OnClick - Show mobile menu button
	 *
	 * Show mobile menu sidebar & body overlay
	 */

	$(document).on('click', '#showMobileMenu', function (event) {
		event.preventDefault();
		$('#sidebarNav').addClass('sidebar-nav--visible');
		$('#bodyOverlay').addClass('body-overlay--visible');
	});

	/*
	 * Event: OnClick - Hide mobile menu button
	 *
	 * Hide mobile menu sidebar & body overlay
	 */

	$(document).on('click', '#hideMobileMenu', function (event) {
		event.preventDefault();
		$('#sidebarNav').removeClass('sidebar-nav--visible');
		$('#bodyOverlay').removeClass('body-overlay--visible');
	});

	/*
	 * Event: OnClick - Mobile menu toggler
	 *
	 * Show / Hide mobile sub menu
	 */

	$(document).on('click', '.menu-toggler', function (event) {
		event.preventDefault();
		const button = $(this);
		const link = button.parent();
		const sub = link.siblings('.menu-mobile-sub-wrapper');

		if (link.hasClass('menu-link--visible')) {
			sub.slideToggle();
			link.removeClass('menu-link--visible');
			button.removeClass('menu-toggler--open');
		} else {
			sub.slideToggle();
			link.addClass('menu-link--visible');
			button.addClass('menu-toggler--open');
		}
	});

	/*
	 * ---------------------------------------------------------------
	 * Footer widgets
	 * ---------------------------------------------------------------
	 */

	/*
	 * Event: OnClick - Widget title
	 *
	 * Show / Hide Widget content
	 */

	if (handheldOnly.matches) {
		// Show / Hide widget content
		$(document).on('click', '.widget-title', function (event) {
			event.preventDefault();
			const title = $(this);
			const widget = title.parent('.widget');
			let content;

			if (widget.hasClass('widget_text')) {
				content = title.siblings('.textwidget');
			} else {
				content = title.siblings('.menu');
			}

			if (title.hasClass('widget-title--open')) {
				content.slideUp().removeClass('widget-content--visible');
				title.removeClass('widget-title--open');
			} else {
				$('.widget-title.widget-title--open').removeClass(
					'widget-title--open'
				);
				$('.menu.widget-content--visible')
					.slideUp()
					.removeClass('widget-content--visible');
				$('.textwidget.widget-content--visible')
					.slideUp()
					.removeClass('widget-content--visible');
				content.slideDown().addClass('widget-content--visible');
				title.addClass('widget-title--open');
			}
		});
	}
});
